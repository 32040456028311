<template>
  <MTag
    class="cursor-default"
    :closable="false"
    :style="{ ...currentStyle, maxWidth: '200px' }"
    variant="neutral-lighter"
  >
    <slot name="prefix-text"></slot>
    <MTooltip>
      <template v-slot:trigger>
        <span class="text-ellipsis">{{ $t(status) }}</span>
      </template>
      {{ $t(status) }}
    </MTooltip>
  </MTag>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { getTagStyleFromColor, colors } from '@utils/color'
export default {
  name: 'ApprovalStatusIcon',
  props: {
    status: { type: String, required: true },
    size: { type: String, default: '2x' },
  },
  computed: {
    ...PreferenceComputed,
    color() {
      switch (this.status) {
        case 'pending':
          return colors.orange
        case 'ignored':
          return colors['neutral-light']
        case 'rejected':
          return colors.error
        case 'approved':
        case 'pre_approved':
          return colors.success
        default:
          return colors['neutral-light']
      }
    },
    currentStyle() {
      return getTagStyleFromColor(this.color, this.theme) || {}
    },
  },
}
</script>
