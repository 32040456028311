var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.formFields.length > 0)?_c('FlotoExpandableView',{attrs:{"default-expanded":_vm.defaultExpanded},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var toggle = ref.toggle;
var expanded = ref.expanded;
return [_c('MDivider',{attrs:{"orientation":"end"}},[_c('a',{staticClass:"md-text-sm",on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.$t(expanded ? 'less' : 'more'))+" "+_vm._s(_vm.$t('details'))+" "),_c('MIcon',{attrs:{"name":("chevron-" + (expanded ? 'up' : 'down'))}})],1)])]}}],null,false,3961129529)},[_vm._t("default",function(){return [_c('MRow',{attrs:{"gutter":16}},[_c('MCol',{class:{
          'bg-neutral-lightest': _vm.giveExternalUpdateOptoin,
          'py-2 px-6': _vm.giveExternalUpdateOptoin,
          rounded: _vm.giveExternalUpdateOptoin,
          'min-w-0': true,
        }},[_c('MRow',[(_vm.giveExternalUpdateOptoin)?_c('MCol',{staticClass:"flex",staticStyle:{"justify-content":"flex-end"},attrs:{"size":12}},[_c('MButton',{staticClass:"mx-1",attrs:{"disabled":_vm.disabled,"variant":"neutral-light","shadow":false,"shape":"circle"},on:{"click":_vm.handleEditCustomField}},[_c('MIcon',{attrs:{"name":"pencil"}})],1)],1):_vm._e(),_c('MCol',{attrs:{"size":_vm.colSize}},[_c('AdditionalInfo',_vm._b({attrs:{"disabled":_vm.additionalInfoDisabled,"is-approval":_vm.isApproval,"is-form-rules-available":_vm.isFormRulesAvailable,"module-name":_vm.moduleName}},'AdditionalInfo',_vm.$attrs,false))],1)],1)],1)],1)]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }