<template>
  <div
    class="ai-bot-chat-box h-full"
    :class="{ 'chat-preview': isChatPreview }"
  >
    <div class="ai-bot-card-header px-3 pt-4 pb-6">
      <!-- <div class="flex flex-col">
        <div
          class="bg-primary-alt py-1 px-2 rounded mt-2"
          @click="$emit('back-to-landing')"
        >
          <MIcon name="chevron-left" />
        </div>
      </div> -->
      <div
        class="flex items-center justify-center p-1 rounded"
        style="background-color: var(--page-background-color)"
      >
        <img
          src="@assets/images/logo/motadata.png"
          height="32"
          width="32"
          class="self-center"
        />
      </div>
      <div class="flex-1 pl-3 mx-1">
        <div class="text-xl font-semibold">{{ branding.name }}</div>
        <div class="md-text-xs">{{ branding.email }}</div>
      </div>
      <div v-if="!isChatPreview" class="flex">
        <MDropdown ref="dropdownMenu">
          <template v-slot:trigger>
            <MButton
              id="action-btn"
              variant="transparent"
              class="ml-2"
              :shadow="false"
              shape="circle"
            >
              <MIcon
                name="ellipsis-v"
                size="2x"
                style="color: var(--white-regular)"
              />
            </MButton>
          </template>
          <template v-slot:menu>
            <MMenu class="action-menu">
              <MMenuItem @click="handleStartNewConversation">
                Start New Conversation
              </MMenuItem>
            </MMenu>
          </template>
        </MDropdown>
      </div>
    </div>
    <div class="ai-chat-area">
      <div class="message-container flex flex-1 flex-col min-h-0">
        <div class="flex flex-col min-h-0">
          <AIBotChatMessages
            :key="AIBotchatMessagesKey"
            ref="chatBoxRef"
            :owner="owner"
            :room="room"
            :chat-limit="chatLimit"
            :default-messages="defaultMessages"
            v-bind="
              room && room.isCollaboration
                ? {}
                : {
                    requester: room && room.user,
                  }
            "
            @send-message="handleSendMessage"
            @bot-name="aiBotName = $event"
            @confirmation-message="confirmationMessage = $event"
          />
          <div v-if="isAiTyping" class="typing-animation-wrapper">
            <div class="typing-animation">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
              <template v-if="aiBotName">
                {{ $t('is_typing', { user: aiBotName }) }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <AIBotCompose
        :confirmation-message="confirmationMessage"
        @send-message="handleSendMessage"
      />
      <MessageConfirmModal
        :open="showModal"
        prevent-auto-close-on-confirm
        @hide="showModal = false"
        @confirm="sendMessage"
      >
        <template v-slot:icon>
          <MIcon name="info-circle" class="w-full text-primary" size="2x" />
        </template>
        <template v-slot:message>
          {{ confirmationMessage }}
        </template>
      </MessageConfirmModal>
    </div>
  </div>
</template>

<script>
import Bus from '@utils/emitter'
import { generateId } from '@utils/id'
import Moment from 'moment'
import MessageConfirmModal from './message-confirm-modal'
import AIBotCompose from './ai-bot-compose.vue'
import AIBotChatMessages from './ai-bot-chat-messages.vue'
import { sendMessageApi, chatPreviewSendMessageApi } from '../chat-api'
import { BrandingComputed } from '@state/modules/branding'
import { isLoggedIn } from '@/src/utils/auth'

export default {
  name: 'AIBotChatBox',
  components: { AIBotCompose, AIBotChatMessages, MessageConfirmModal },
  props: {
    room: { type: [Object], default: undefined },
    owner: { type: Object, required: true },
    transferTimeout: { type: Number, default: 0 },
    defaultMessages: {
      type: Array,
      default() {
        return []
      },
    },
    defaultUnreadCount: { type: Number, default: 0 },
    chatLimit: { type: Number, default: 50 },
    isChatPreview: { type: Boolean, default: false },
  },
  data() {
    return {
      AIBotchatMessagesKey: 1,
      isAiTyping: false,
      aiBotName: undefined,
      confirmationMessage: undefined,
      showModal: false,
      pendingMessage: null,
    }
  },
  computed: {
    ...BrandingComputed,
  },
  created() {},
  methods: {
    handleSendMessage(message) {
      if (this.confirmationMessage) {
        this.showModal = true
        this.pendingMessage = message
      } else {
        this.showModal = false
        this.sendMessage(message)
      }
    },
    sendMessageFn(msg) {
      if (this.isChatPreview) {
        return chatPreviewSendMessageApi(msg, this.$route.params.id)
      }
      return sendMessageApi(msg)
    },
    sendMessage(m) {
      let message = m
      if (this.pendingMessage) {
        message = this.pendingMessage
      }
      const msg = {
        id: generateId(),
        ...(isLoggedIn() ? { owner: this.owner.id } : {}),
        date: Moment().valueOf(),
        ...message,
      }
      Bus.$emit('app:send-message', [msg])
      this.isAiTyping = true
      this.sendMessageFn(msg)
        .then((data) => {
          if (data) {
            if (data.length > 0) {
              const aiBotName = data[0].ownerName
              this.aiBotName = aiBotName
            }
            Bus.$emit('app:receive-message', data)
          }
          this.isAiTyping = false
        })
        .catch(() => (this.isAiTyping = false))
        .finally(() => {
          this.showModal = false
          this.pendingMessage = null
        })
    },
    handleStartNewConversation() {
      this.$emit('start-new-conversation')
    },
  },
}
</script>
