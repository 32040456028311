<template>
  <FlotoExpandableView
    v-if="formFields.length > 0"
    :default-expanded="defaultExpanded"
  >
    <template v-slot:trigger="{ toggle, expanded }">
      <MDivider orientation="end">
        <a class="md-text-sm" @click="toggle">
          {{ $t(expanded ? 'less' : 'more') }}
          {{ $t('details') }}
          <MIcon :name="`chevron-${expanded ? 'up' : 'down'}`" />
        </a>
      </MDivider>
    </template>
    <slot>
      <MRow :gutter="16">
        <MCol
          :class="{
            'bg-neutral-lightest': giveExternalUpdateOptoin,
            'py-2 px-6': giveExternalUpdateOptoin,
            rounded: giveExternalUpdateOptoin,
            'min-w-0': true,
          }"
        >
          <MRow>
            <MCol
              v-if="giveExternalUpdateOptoin"
              :size="12"
              style="justify-content: flex-end"
              class="flex"
            >
              <MButton
                :disabled="disabled"
                variant="neutral-light"
                class="mx-1"
                :shadow="false"
                shape="circle"
                @click="handleEditCustomField"
              >
                <MIcon name="pencil" />
              </MButton>
            </MCol>
            <MCol :size="colSize">
              <AdditionalInfo
                :disabled="additionalInfoDisabled"
                :is-approval="isApproval"
                :is-form-rules-available="isFormRulesAvailable"
                v-bind="$attrs"
                :module-name="moduleName"
              />
            </MCol>
          </MRow>
        </MCol>
      </MRow>
    </slot>
  </FlotoExpandableView>
</template>

<script>
import { FormComputed } from '@state/modules/form'
import { authComputed } from '@state/modules/auth'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'
import AdditionalInfo from './additional-info'

export default {
  name: 'AdditionalInfoWithExpand',
  components: { AdditionalInfo },
  props: {
    disabled: { type: Boolean, default: false },
    defaultExpanded: { type: Boolean, default: false },
    isApproval: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    colSize: { type: Number, default: 8 },
    isFormRulesAvailable: { type: Boolean, default: false },
  },
  data() {
    return {
      showMoreDetails: false,
    }
  },
  computed: {
    ...FormComputed,
    ...authComputed,
    formFields() {
      if (!this[`${this.moduleName}Fields`]) {
        throw new Error(`given module ${this.moduleName} is not found in store`)
      }
      if (this.moduleName === this.$constants.REQUEST) {
        if (this.isPortalLogin) {
          const fields = this[`${this.moduleName}Fields`]({
            exclude: ['system', 'api'],
          }).filter((f) => f.useOnPortal)
          if (this.isApproval) {
            return fields
          }
          return getFieldByRequesterAccess(fields, this.user)
        }
        return this[`${this.moduleName}Fields`]({
          exclude: ['system', 'api'],
        })
      }
      if (this.moduleName === this.$constants.PURCHASE) {
        return this[`${this.moduleName}Fields`]().filter(
          (f) => !f.attributes.costField || f.attributes.costField === 'false'
        )
      }
      return this[`${this.moduleName}Fields`]()
    },
    additionalInfoDisabled() {
      if (this.moduleName !== this.$constants.REQUEST) {
        return this.disabled
      }
      if (this.disabled) {
        return this.disabled
      }
      return this.isFormRulesAvailable
    },
    giveExternalUpdateOptoin() {
      return !this.disabled && this.isFormRulesAvailable
    },
  },
  methods: {
    handleEditCustomField() {
      if (!this.disabled) {
        this.$emit('edit-additional-info')
      }
    },
  },
}
</script>
