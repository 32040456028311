var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('MRow',{staticClass:"flex-1 pr-2",attrs:{"gutter":16}},[_c('MCol',{attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"approval-type","label":_vm.$t('set_decision'),"vid":(_vm.guid + "-approvalType")}},[_c('MRadioGroup',{attrs:{"as-button":"","options":_vm.approvalTypeOptions},model:{value:(_vm.approvalType),callback:function ($$v) {_vm.approvalType=$$v},expression:"approvalType"}})],1),_c('FlotoFormItem',{attrs:{"id":"requester-group","label":_vm.$t('requester_group'),"vid":(_vm.guid + "-requesterGroups")}},[_c('FlotoRequesterGroupPicker',{attrs:{"multiple":"","as-input":"","mandatory-selection":""},model:{value:(_vm.requesterGroups),callback:function ($$v) {_vm.requesterGroups=$$v},expression:"requesterGroups"}})],1),_c('FlotoFormItem',{attrs:{"id":"technician-group","label":_vm.$t('technician_group'),"vid":(_vm.guid + "-technicianGroups")}},[_c('FlotoTechnicianGroupPicker',{attrs:{"multiple":"","as-input":"","mandatory-selection":""},model:{value:(_vm.technicianGroups),callback:function ($$v) {_vm.technicianGroups=$$v},expression:"technicianGroups"}})],1),_c('FlotoFormItem',{attrs:{"id":"requester-picker","label":_vm.$t('set_users'),"vid":(_vm.guid + "-users")}},[_c('FlotoRequesterPicker',{attrs:{"multiple":"","as-input":""},model:{value:(_vm.users),callback:function ($$v) {_vm.users=$$v},expression:"users"}})],1),(
          [
            _vm.$constants.ASSET,
            _vm.$constants.ASSET_HARDWARE,
            _vm.$constants.ASSET_NON_IT,
            _vm.$constants.ASSET_CONSUMABLE ].indexOf(_vm.moduleName) >= 0
        )?_c('FlotoFormItem',{attrs:{"id":"include-asset-user","validation-label":_vm.$t('user'),"vid":(_vm.guid + "-includeAssetUser")}},[_c('MCheckbox',{model:{value:(_vm.includeAssetUser),callback:function ($$v) {_vm.includeAssetUser=$$v},expression:"includeAssetUser"}},[_vm._v(" "+_vm._s(_vm.$tc('asset'))+" "+_vm._s(_vm.$tc('user'))+" ")])],1):_vm._e(),(
          [
            _vm.$constants.REQUEST,
            _vm.$constants.SERVICE_CATALOG,
            _vm.$constants.PROBLEM,
            _vm.$constants.CHANGE,
            _vm.$constants.RELEASE ].indexOf(_vm.moduleName) >= 0
        )?_c('FlotoFormItem',{staticClass:"mb-0",attrs:{"id":"include-manager-user","validation-label":_vm.$t('manager_head'),"vid":(_vm.guid + "-includeManagerUser")}},[_c('MCheckbox',{model:{value:(_vm.includeManagerUser),callback:function ($$v) {_vm.includeManagerUser=$$v},expression:"includeManagerUser"}},[_vm._v(" "+_vm._s(_vm.$tc('manager_head'))+" ")])],1):_vm._e(),(
          [
            _vm.$constants.REQUEST,
            _vm.$constants.SERVICE_CATALOG,
            _vm.$constants.PROBLEM,
            _vm.$constants.CHANGE,
            _vm.$constants.RELEASE ].indexOf(_vm.moduleName) >= 0
        )?_c('FlotoFormItem',{staticClass:"mb-0",attrs:{"id":"include-assignee-manager-user","validation-label":_vm.$t('assignee_manager'),"vid":(_vm.guid + "-includeAssigneeManagerUser")}},[_c('MCheckbox',{model:{value:(_vm.includeAssigneeManagerUser),callback:function ($$v) {_vm.includeAssigneeManagerUser=$$v},expression:"includeAssigneeManagerUser"}},[_vm._v(" "+_vm._s(_vm.$tc('assignee_manager'))+" ")])],1):_vm._e(),(
          [
            _vm.$constants.REQUEST,
            _vm.$constants.SERVICE_CATALOG,
            _vm.$constants.PROBLEM,
            _vm.$constants.CHANGE,
            _vm.$constants.RELEASE ].indexOf(_vm.moduleName) >= 0
        )?_c('FlotoFormItem',{attrs:{"id":"include-department-head","validation-label":_vm.$t('department_head'),"vid":(_vm.guid + "-includeDepartmetHead")}},[_c('MCheckbox',{model:{value:(_vm.includeDepartmetHead),callback:function ($$v) {_vm.includeDepartmetHead=$$v},expression:"includeDepartmetHead"}},[_vm._v(" "+_vm._s(_vm.$tc('department_head'))+" ")])],1):_vm._e()],1),_c('MCol',{attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"subject","label":_vm.$t('subject'),"rules":"required|subject","vid":(_vm.guid + "-subject"),"placeholder":_vm.$t('subject')},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('FlotoFormItem',{attrs:{"id":"description","rows":4,"label":_vm.$t('description'),"vid":(_vm.guid + "-description"),"placeholder":_vm.$t('description_instruction'),"type":"textarea"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),(_vm.actionInputIndex !== 0)?_c('MRow',{staticClass:"justify-end relative",style:({ top: '25px', marginTop: '-25px' }),attrs:{"gutter":16}},[_c('MCol',{staticClass:"flex-no-grow",attrs:{"auto-size":""}},[_vm._t("actions"),_c('MTooltip',{attrs:{"placement":"bottomRight"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',{staticClass:"text-secondary-red remove-action",on:{"click":function($event){return _vm.$emit('remove')}}},[_c('MIcon',{attrs:{"name":"times-circle","size":"lg"}})],1)]},proxy:true}],null,false,174035364)},[_vm._v(" "+_vm._s(_vm.$t('remove'))+" "+_vm._s(_vm.$tc('action'))+" ")])],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }