<template>
  <div
    :style="{ right: `${right || 0}px` }"
    class="chat-box"
    :class="{ hidden: !isOpen }"
  >
    <div class="chat-header" @click="toggle">
      <div class="chat-header-container">
        <div class="chat-name">
          <div class="chat-name-span flex items-center">
            <FlotoUserAvatar
              v-if="chatHeader.avatar"
              :size="25"
              style="min-width: 25px"
              :avatar="chatHeader.avatar"
              show-online-status
              :is-online="isOnline"
              class="mr-2"
            />
            <div class="text-ellipsis">
              {{ chatHeader.name }}
            </div>
          </div>
        </div>
        <MBadge :count="unreadCount" class="mr-1"> &nbsp; &nbsp; </MBadge>
      </div>
      <div class="chat-actions" @click.prevent="handleAction">
        <div v-if="canMinimize" @click.stop.prevent="toggle">
          <MIcon :name="`chevron-${isOpen ? 'down' : 'up'}`" />
        </div>
        <div @click="$emit('on-close')">
          <MIcon name="times" />
        </div>
      </div>
    </div>
    <slot v-if="isOpen" name="connection-error" />
    <div class="chat-area" :class="{ hidden: !isOpen }">
      <div class="message-container flex flex-1 flex-col min-h-0">
        <div class="flex flex-col min-h-0">
          <ChatMessages
            :key="chatMessagesKey"
            ref="chatBoxRef"
            :owner="owner"
            :socket="socket"
            :event-prefix="eventPrefix"
            :room="room"
            :chat-limit="chatLimit"
            :default-messages="defaultMessages"
            v-bind="
              room && room.isCollaboration
                ? {}
                : {
                    requester: room && room.user,
                  }
            "
          />
          <div v-if="isOnline" class="typing-animation-wrapper">
            <div
              class="typing-animation"
              :style="{ visibility: typerName ? 'visible' : 'hidden' }"
            >
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
              <template v-if="typerName">
                {{ $t('is_typing', { user: typerName }) }}
              </template>
            </div>
          </div>
          <div v-else class="user-status">
            {{ $t('is_offline', { user: chatHeader.name }) }}
          </div>
          <div class="user-status">
            <div v-if="transferRemark">
              {{ transferRemark }}
            </div>
            <slot name="remark" />
          </div>
        </div>
      </div>
      <Compose
        :disabled="disabled"
        :has-file="Boolean(uploadedFile)"
        @send-message="sendMessage"
        @start-typing="onStartTyping"
        @stop-typing="onStopTyping"
      />
      <div v-if="uploadedFile" class="flex file-item items-center">
        <div class="flex-1 min-w-0 text-ellipsis">
          <MIcon name="file-alt" class="mr-1" />
          {{ uploadedFile.realName }}
        </div>
        <MIcon
          name="times"
          class="cursor-pointer"
          @click="uploadedFile = null"
        />
      </div>
      <div
        v-if="shouldShowActions"
        class="flex justify-between px-2 py-1 pr-6 chat-actions"
      >
        <div class="box">
          <MTooltip>
            <template v-slot:trigger="{ toggle: showTooltip }">
              <FlotoAttachment
                :show-upload-list="false"
                :max-files="1"
                @change="handleFileUploaded"
              >
                <MButton
                  variant="transparent"
                  :shadow="false"
                  shape="circle"
                  @mouseenter="showTooltip"
                  @mouseleave="showTooltip"
                >
                  <MIcon name="paperclip" />
                </MButton>
              </FlotoAttachment>
            </template>
            {{ $tc('attach_file', 2) }}
          </MTooltip>
        </div>
        <template v-if="room && !room.isCollaboration && !isPortalLogin">
          <div class="box">
            <MTooltip>
              <template v-slot:trigger>
                <FlotoTechnicianPicker
                  :include-ids="includedTechnicians"
                  @change="handleTransferChat"
                >
                  <template v-slot:trigger="{ toggle: openDropdown }">
                    <MButton
                      variant="transparent"
                      :shadow="false"
                      shape="circle"
                      @click.prevent="openDropdown"
                    >
                      <MIcon name="user-plus" />
                    </MButton>
                  </template>
                </FlotoTechnicianPicker>
              </template>
              {{ $t('transfer') }} {{ $tc('chat') }}
            </MTooltip>
          </div>
          <div class="box">
            <MTooltip>
              <template v-slot:trigger="{ toggle: showTooltip }">
                <MButton
                  variant="transparent"
                  :shadow="false"
                  shape="circle"
                  @mouseenter="showTooltip"
                  @mouseleave="showTooltip"
                  @click.prevent="handleShowCreateRequestForm"
                >
                  <MIcon name="ticket-alt" />
                </MButton>
              </template>
              {{ $t('create') }} {{ $tc('request') }}
            </MTooltip>
          </div>
          <div class="box">
            <MTooltip>
              <template v-slot:trigger="{ toggle: showTooltip }">
                <MButton
                  variant="transparent"
                  :shadow="false"
                  shape="circle"
                  @click.prevent="handleAction({ key: 'close' })"
                  @mouseenter="showTooltip"
                  @mouseleave="showTooltip"
                >
                  <MIcon name="times" />
                </MButton>
              </template>
              {{ $t('close') }}
            </MTooltip>
          </div>
        </template>
      </div>
    </div>
    <FlotoDrawerForm
      v-if="room && !room.isCollaboration && !isPortalLogin"
      :open="showCreateRequestDrawer"
      width="65%"
      @cancel="showCreateRequestDrawer = false"
      @submit="handleCreateRequest"
    >
      <template v-slot:header>
        {{ $t('create') }} {{ $tc('request') }}
      </template>
      <TicketForm
        :value="requestFormData"
        :module-name="$constants.REQUEST"
        :processing="processing"
        :with-submit="false"
        @templateSelected="handleTemplateSelected"
        @change="handleChangeCreatingResource"
      />
      <template v-slot:actions="{ hide, submit }">
        <MButton outline class="mr-2" :loading="processing" @click="submit">
          {{ $t('create') }} {{ $tc('request') }}
        </MButton>
        <MButton variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </div>
</template>

<script>
import Flatten from 'lodash/flatten'
import Bus from '@utils/emitter'
import Datetime from '@src/filters/datetime'
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import TicketForm from '@modules/ticket/components/ticket-form'
import { createApi } from '@modules/ticket/ticket-api'
import { SourceComputed } from '@state/modules/source'
import {
  createMessageApi,
  updateChatRoomApi,
  getChatSupportedUsersApi,
} from '../chat-api'
import Compose from './compose.vue'
import ChatMessages from './chat-messages.vue'

export default {
  name: 'ChatBox',
  components: { Compose, ChatMessages, TicketForm },
  props: {
    socket: { type: Object, required: true },
    room: { type: [Object], default: undefined },
    owner: { type: Object, required: true },
    transferTimeout: { type: Number, default: 0 },
    eventPrefix: { type: String, default: '' },
    defaultMessages: {
      type: Array,
      default() {
        return []
      },
    },
    onlineUsers: {
      type: Array,
      default() {
        return []
      },
    },
    defaultUnreadCount: { type: Number, default: 0 },
    createMessageFn: { type: Function, default: undefined },
    defaultVisible: { type: Boolean, default: false },
    // eslint-disable-next-line
    canMinimize: { type: Boolean, default: true },
    chatLimit: { type: Number, default: 50 },
    right: { type: [Number], default: 0 },
    forTechnician: { type: Boolean, default: false },
  },
  data() {
    this.messageBoxScrollPosition = 0
    this.actionMenus = [{ key: 'close', text: this.$t('close') }]
    this.transferTimer = null
    return {
      showCreateRequestDrawer: false,
      requestFormData: {},
      processing: false,
      isOpen: this.defaultVisible,
      typerName: false,
      unreadCount: this.defaultUnreadCount,
      chatMessagesKey: 1,
      disabled: this.room
        ? this.room.status &&
          ['on_going', 'pending'].indexOf(this.room.status) === -1 &&
          !this.room.isCollaboration
        : false,
      transferRemark: null,
      chatSupportedUserIds: [],
      uploadedFile: null,
    }
  },
  computed: {
    ...TechnicianComputed,
    ...authComputed,
    ...SourceComputed,
    includedTechnicians() {
      const onlineUsers = this.onlineUsers
      const user = this.user
      const supportedIds = this.chatSupportedUserIds
      return onlineUsers.filter(
        (id) =>
          id !== user.id &&
          supportedIds.indexOf(id) >= 0 &&
          id !== this.room.user.id
      )
    },
    shouldShowActions() {
      const room = this.room
      if (!room) {
        return true
      }
      if (room.isCollaboration) {
        return true
      }
      if (['on_going', 'pending'].indexOf(room.status) >= 0) {
        return true
      }
      return false
    },
    isOnline() {
      const room = this.room
      if (room) {
        const user = room.members.filter((m) => m !== this.owner.id)
        if (user.length <= 0) {
          return true
        }
        // @TTODO if multiple users then think here
        return this.onlineUsers.indexOf(user[0]) >= 0
      }
      return true
    },
    chatHeader() {
      const room = this.room
      if (!room) {
        return { name: this.$tc('support_channel') }
      }
      const users = room.members.filter((m) => m !== this.owner.id)
      if (users.length <= 0) {
        return { name: this.$tc('support_channel') }
      }
      if (users.length === 1) {
        const technician = this.technicianOptions.find((t) => t.id === users[0])
        if (technician) {
          return technician
        }
        return room.user
      }
      return { name: `${users.length} ${this.$tc('technician', 2)}` }
    },
  },
  watch: {
    isOpen(newValue, oldValue) {
      if (newValue !== oldValue && newValue && this.room) {
        this.resetUnreadCount()
      }
    },
    room(newValue, oldValue) {
      if (newValue && newValue.id) {
        if (!oldValue || (oldValue && oldValue.id !== newValue.id)) {
          this.socket.emit(`${this.eventPrefix}-create_room`, {
            roomId: this.room.id,
          })
          this.bindSocketEvents()
          this.chatMessagesKey++
        }
      }
    },
    'socket.connected': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue && this.room && this.room.id) {
          this.socket.emit(`${this.eventPrefix}-create_room`, {
            roomId: this.room.id,
          })
          this.bindSocketEvents()
          this.chatMessagesKey++
        }
      },
    },
  },
  mounted() {
    if (this.room) {
      this.socket.emit(`${this.eventPrefix}-create_room`, {
        roomId: this.room.id,
      })
      this.socket.on(
        `${this.eventPrefix}-transfer_rejected`,
        this.handleRejectedChat
      )
      this.bindSocketEvents()
      if (this.room && !this.room.isCollaboration && !this.isPortalLogin) {
        this.getChatSupportedUsers()
      }
    }
    Bus.$on('chat_channel_updated', this.handleRoomUpdate)
  },
  beforeDestroy() {
    if (this.transferTimer) {
      clearTimeout(this.transferTimer)
    }
    this.socket.off(`${this.eventPrefix}-message`, this.onMessageReceived)
    this.socket.off(`${this.eventPrefix}-start_typing`, this.handleStartTyping)
    this.socket.off(`${this.eventPrefix}-stop_typing`, this.handleStopTyping)
    this.socket.off(
      `${this.eventPrefix}-transfer_rejected`,
      this.handleRejectedChat
    )
    Bus.$off('chat_channel_updated', this.handleRoomUpdate)
  },
  methods: {
    getChatSupportedUsers() {
      getChatSupportedUsersApi().then((data) => {
        this.chatSupportedUserIds = data.map(({ id }) => id)
      })
    },
    handleRoomUpdate(room) {
      if (
        ['pending', 'on_going'].indexOf(room.status) === -1 &&
        this.room &&
        this.room.id === room.id
      ) {
        this.disabled = true
      }
    },
    handleRejectedChat(transferRequest) {
      if (transferRequest.room.id !== this.room.id) {
        return
      }
      if (transferRequest.from !== this.user.id) {
        return
      }
      const technician = this.technicianOptions.find(
        (t) => t.id === transferRequest.technician
      )
      this.transferRemark = this.$t('chat_transfer_rejected', {
        technician: technician.name,
      })
    },
    handleTransferChat(technician) {
      this.socket.emit(`${this.eventPrefix}-transfer_request`, {
        room: this.room,
        technician,
        from: this.user.id,
      })
      this.transferTimer = setTimeout(() => {
        this.transferRemark = this.$t('chat_transfer_timeout')
      }, this.transferTimeout)
    },
    handleAction(action) {
      if (action.key === 'close') {
        updateChatRoomApi(this.room.id, { status: 'completed' })
      }
    },
    onMessageReceived(message) {
      if (
        this.room &&
        this.room.id === message.roomId &&
        message.owner !== this.user.id
      ) {
        if (!this.isOpen) {
          this.unreadCount += 1
        }
      }
    },
    handleStartTyping(event) {
      if (
        this.room &&
        this.room.id === event.roomId &&
        event.userId !== this.owner.id
      ) {
        this.typerName = event.name
      }
    },
    handleStopTyping(event) {
      if (
        this.room &&
        this.room.id === event.roomId &&
        event.userId !== this.owner.id
      ) {
        this.typerName = null
      }
    },
    unbindSocketEvents() {
      this.socket.off(`${this.eventPrefix}-message`, this.onMessageReceived)
      this.socket.off(
        `${this.eventPrefix}-start_typing`,
        this.handleStartTyping
      )
      this.socket.off(`${this.eventPrefix}-stop_typing`, this.handleStopTyping)
    },
    bindSocketEvents() {
      this.socket.on(`${this.eventPrefix}-message`, this.onMessageReceived)
      this.socket.on(`${this.eventPrefix}-start_typing`, this.handleStartTyping)
      this.socket.on(`${this.eventPrefix}-stop_typing`, this.handleStopTyping)
    },
    isVisible() {
      return this.isOpen
    },
    toggle() {
      if (!this.isVisible()) {
        this.$emit('on-reset-unread-count')
      }
      this.isOpen = !this.isOpen
      this.$nextTick(() => {
        if (!this.isOpen) {
          this.$emit('on-minimize')
        }
        this.resetScrollToBottom()
      })
    },
    resetScrollToBottom() {
      if (this.$refs.chatBoxRef) {
        this.$refs.chatBoxRef.resetScrollToBottom()
      }
    },
    handleFileUploaded(event) {
      this.sendMessage(undefined, event)
    },
    sendMessage(message, fileAttachments) {
      this.onStopTyping()
      if (this.createMessageFn) {
        return this.createMessageFn(message, fileAttachments).then((data) => {
          this.uploadedFile = null
          this.socket.emit(`${this.eventPrefix}-message`, {
            id: data.id,
            message: data.message,
            fileAttachments: data.fileAttachments || [],
            roomId: this.room.id,
            room: this.room,
            members: this.room.members,
            owner: this.owner.id,
            ownerName: this.owner.name,
            date: data.date,
          })
        })
      }
      createMessageApi(this.room.id, message, fileAttachments).then((data) => {
        this.uploadedFile = null
        this.socket.emit(`${this.eventPrefix}-message`, {
          id: data.id,
          message: data.message,
          fileAttachments: data.fileAttachments || [],
          roomId: this.room.id,
          room: this.room,
          members: this.room.members,
          owner: this.owner.id,
          ownerName: this.owner.name,
          date: data.date,
        })
      })
    },
    resetUnreadCount() {
      if (this.unreadCount) {
        this.$emit('on-reset-unread-count')
        this.unreadCount = 0
        this.resetScrollToBottom()
      }
    },
    onStartTyping() {
      if (!this.room) {
        return
      }
      this.socket.emit(`${this.eventPrefix}-start_typing`, {
        roomId: this.room.id,
        userId: this.owner.id,
        name: this.owner.name,
      })
    },
    onStopTyping() {
      if (!this.room) {
        return
      }
      this.socket.emit(`${this.eventPrefix}-stop_typing`, {
        roomId: this.room.id,
        userId: this.owner.id,
        name: this.owner.name,
      })
    },
    handleTemplateSelected(template) {
      if (!Object.keys(template).length) {
        this.requestFormData = {}
      }
    },
    handleChangeCreatingResource(data) {
      this.requestFormData = {
        ...this.requestFormData,
        ...data,
      }
    },
    handleShowCreateRequestForm() {
      const messages = this.$refs.chatBoxRef.getMessages()
      const roomUser = this.room.user
      const users = {
        [roomUser.id]: roomUser.name,
      }
      this.technicianOptions.forEach((tech) => (users[tech.id] = tech.name))
      const description = messages
        .filter((m) => m.message)
        .map(
          (m) =>
            `<p>${m.ownerName} ${this.$tc('at')} ${Datetime(
              m.date,
              'DD/MM/YYYY hh:mm A'
            )}]: ${m.message}</p>`
        )
        .join('')
      const files = Flatten(
        messages
          .filter((m) => (m.fileAttachments || []).length)
          .map((m) => m.fileAttachments)
      )
      this.requestFormData = {
        description,
        requesterEmail: roomUser.email,
        fileAttachments: files,
      }
      this.showCreateRequestDrawer = true
    },
    handleCreateRequest() {
      this.processing = true
      const sourceOption = this.sourceOptions.find(
        (s) => s.systemName === 'chat'
      )
      const formData = {
        ...this.requestFormData,
        sourceId: sourceOption.key,
      }
      createApi(this.$constants.REQUEST, formData)
        .then((data) => {
          this.showCreateRequestDrawer = false
        })
        .finally(() => (this.processing = false))
    },
  },
}
</script>
