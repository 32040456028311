<template>
  <div
    class="message"
    :class="{ 'my-message': isMyMessage }"
    @click="toggleSendTime"
  >
    <!-- <div class="message-user">
      {{ isMyMessage && user.id === senderId ? $t('me') : senderName }}
    </div> -->
    <div
      v-if="!(attachments || []).length"
      class="flex"
      :class="{ 'justify-end': isMyMessage }"
    >
      <component
        :is="messageComponent"
        :message="message"
        :is-my-message="isMyMessage"
        :confirmation-message="confirmationMessage"
        @send-message="$emit('send-message', $event)"
      >
        <template v-if="!isMyMessage" v-slot:bot-avatar>
          <div class="flex flex-col items-center justify-end mx-2">
            <img
              src="@assets/images/logo/motadata.png"
              :height="size"
              :width="size"
              class="self-center"
            />
          </div>
        </template>
        <template v-if="isMyMessage" v-slot:sender-avatar>
          <div class="flex flex-col items-center justify-end mx-2">
            <FlotoUserAvatar :size="size" :user-id="senderId" />
          </div>
        </template>
      </component>
    </div>
    <div
      v-else-if="attachments && (attachments || []).length"
      class="content cursor-pointer link"
      :class="{ 'my-message': isMyMessage }"
      @click="handleFileDownload(attachments[0])"
    >
      <MIcon name="file-alt" /> {{ attachments[0].realName }}
    </div>
    <div class="message-datetime md-text-xs px-12 py-1">
      {{ date | datetime }}
    </div>
  </div>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import { transformAttachment } from '@data/attachment'
import UserTextMessage from './message-renderer/user-text-message'
import UserDateMessage from './message-renderer/user-date-message'
import UserStaticChoiceMessage from './message-renderer/user-static-choice-message'
import UserRefChoiceMessage from './message-renderer/user-ref-choice-message'
import BotResponseTextMessage from './message-renderer/bot-response-text-message'
import BotResponseButtonMessage from './message-renderer/bot-response-button-message'
import UtilityPluginMessage from './message-renderer/utility-plugin-message'
import UtilityTransferChatMessage from './message-renderer/utility-transfer-chat-message'
import { chatBrainType } from '@data/ai-bot'

export default {
  name: 'AIBotMessage',
  components: {
    UserTextMessage,
    UserDateMessage,
    UserStaticChoiceMessage,
    UserRefChoiceMessage,
    BotResponseTextMessage,
    BotResponseButtonMessage,
    UtilityPluginMessage,
    UtilityTransferChatMessage,
  },
  props: {
    message: { type: Object, required: true },
    senderId: { type: Number, required: true },
    senderName: { type: String, default: '' },
    isMyMessage: { type: Boolean, default: false },
    date: { type: [Number, Object], default: undefined },
    requester: { type: Object, default: undefined },
    participant: { type: Object, default: undefined },
    attachments: { type: Array, default: undefined },
    confirmationMessage: { type: String, default: undefined },
  },
  data() {
    this.size = 30
    return {
      showSendTime: false,
    }
  },
  computed: {
    ...TechnicianComputed,
    ...authComputed,
    messageComponent() {
      if (this.message.responseType === chatBrainType.USER_INPUT_DATE) {
        return UserDateMessage
      }
      if (this.message.responseType === chatBrainType.USER_STATIC_CHOICE) {
        return UserStaticChoiceMessage
      }
      if (this.message.responseType === chatBrainType.USER_REF_CHOICE) {
        return UserRefChoiceMessage
      }
      if (this.message.responseType === chatBrainType.BOT_RESPONSE_TEXT) {
        return BotResponseTextMessage
      }
      if (this.message.responseType === chatBrainType.BUTTON) {
        return BotResponseButtonMessage
      }
      if (
        this.message.responseType === chatBrainType.PLUGIN_UTILITY ||
        this.message.responseType === chatBrainType.SEARCH_UTILITY ||
        this.message.responseType === chatBrainType.ACTION_UTILITY
      ) {
        return UtilityPluginMessage
      }
      if (this.message.responseType === chatBrainType.TRANSFER_TO_TECHNICIAN) {
        return UtilityTransferChatMessage
      }
      return UserTextMessage
    },
  },
  methods: {
    handleFileDownload(attachment) {
      const a = transformAttachment(attachment, true, this.isPortalLogin)
      window.open(`${a.url}${this.accessToken}`, '_blank')
    },
    toggleSendTime() {
      this.showSendTime = !this.showSendTime
    },
  },
}
</script>

<style lang="less" scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
